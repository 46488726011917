var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_c('v-btn', {
    on: {
      "click": _vm.setTemplate
    }
  }, [_vm._v("LOAD TEMPLATE")]), _c('div', {
    attrs: {
      "id": "container"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }